import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
} from "@mui/material";
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';

import "dayjs/locale/de";
const operations = [
  "Болка",
  "Бондинг",
  "Белеење",
  "ГБТ",
  "Демонтирање",
  "Дефинитивно полнење",
  "Екстракција",
  "Екстракција млечен заб",
  "Ендодонција",
  "Залевање",
  "Кoлче",
  "Консултација",
  "Корекција бондинг",
  "Корекција на протеза",
  "Ласер",
  "Мерка",
  "ОПЏ",
  "Падната пломба",
  "Полирање",
  "Пломбирање",
  "Проба",
  "Преглед",
  "Препарација",
  "Приватен",
  "Прв пат",
  "Скршен заб",
  "Цементирање",
  "Циркон",
  "ЧЗК",
  "Филер",
  "Филер"
];


const columns = [
  //{ field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'patient_name',
    headerName: 'Име и Презиме',
    width: 150,
  },
  {
    field: 'phone_number',
    headerName: 'Телефонски Број',
    width: 150,
  },
  {
    field: 'created_for',
    headerName: 'Термин',
    type: 'number',
    width: 110,
    valueGetter: (value, row) => `${new Date(row.created_for).getDate()}.${new Date(row.created_for).getMonth()+1}`,

  },
  {
    field: 'doctor',
    headerName: 'Доктор',
    sortable: false,
    width: 160,
    valueGetter: (value, row) => `${row.doctor.user.first_name || ''}`,
  },
];


const AppointmentFilter = () => {
  const [operation, setOperation] = useState([]);
  const [month, setMonth] = useState(1);
  const [year, setYear] = useState(2024);
  const [appointments, setAppointments] = useState([])

  const changeOperation = (val) =>{
    setOperation(val)
  };

  const changeMonth = (val) =>{
    setMonth(val)
  };

  const changeYear = (val) =>{
    setYear(val)
  };

  const handleClick = () => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const headers = {
      Authorization: `Token ${localStorage.getItem("key")}`, // Use 'Bearer' if you're sending a JWT token
      "Content-Type": "application/json", // Adjust content type as needed
    };
    let data = { operation: operation, month: month, year:year };
    console.log(data)
    axios.get(baseUrl + "/schedules/list-of-appointments/", { headers: headers , params:data})
          .then(function (response) {
              setAppointments(response.data)

          })
          
    
  };

  return (
    <div id="filters">
      <Container sx={{ background: "#f3f3f3", marginTop: "3%", padding: "1%" }}>
        <h2 style={{ paddingLeft: "50px" }}>Филтер за термини</h2>
        <Box
          sx={{
            paddingLeft: { xs: "10px", sm: "30px", md: "50px" },
            paddingRight: { xs: "10px", sm: "30px", md: "50px" },
            mt: 2,
          }}
        >
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} sm={6} md={3}>
              <FormControl
                sx={{ width: "100%", height: 80, paddingBottom: 2 }}
                className="doctor-select"
              >
                <InputLabel id="doctor-select-label">Операција</InputLabel>
                <Select
                  labelId="doctor-select-label"
                  id="demo-simple-select"
                  value={operation || ""}
                  label="Операција"
                  onChange={(e) => changeOperation(e.target.value)}
                >
                  {operations.map((doctor, index) => (
                    <MenuItem key={index} value={doctor}>
                      {doctor}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              style={{ marginTop: { xs: "5%", sm: "0%", md: "0%" } }}
            >
              <TextField
                variant="outlined"
                value={month}
                fullWidth
                margin="normal"
                label="Месец"
                className="doctor-inputs"
                type="number"
                onChange={(e) => changeMonth(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                variant="outlined"
                value={year}
                fullWidth
                margin="normal"
                label="Година"
                className="doctor-inputs"
                type="number"
                onChange={(e) => changeYear(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Button
                style={{
                  width: "100%",
                  height: 55,
                  marginTop: "17px",
                  backgroundColor: "#F4BFC5",
                  color: "#ffffff",
                }}
                variant="standard"
                onClick={() => handleClick()}
              >
                Генерирај извештај
              </Button>
            </Grid>
          </Grid>
          <DataGrid
        rows={appointments}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20]}
        checkboxSelection
        disableRowSelectionOnClick
      />
        </Box>
        <br />
        <div></div>
      </Container>
    </div>
  );
};

export default AppointmentFilter;
